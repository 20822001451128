<template>
  <v-dialog
    :value="value"
    max-width="1300"
    width="1300"
    persistent>
  <v-card tile flat>
    <v-card-title class="px-5 pt-4">
      Related Promo Groups
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="selected.length === 0"
        @click="addGroups">
        Add to Feature
      </v-btn>
      <v-btn class="ml-2" icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      {{ promo.promo_category_name }} | {{ promo.name }}
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-container fluid class="pa-0">
      <v-data-table
        :headers="tableHeaders"
        :items="relatedPromos"
        dense
        :height="tableSize"
        group-by="promo_item_group_id"
        :custom-sort="customSort"
        fixed-header
        :footer-props="{'items-per-page-options': [-1]}"
        :items-per-page="-1">
        <template v-slot:[`header.select-all`]>
          <v-checkbox
            v-model="allSelected"
            class="my-auto mx-0"
            hide-details
            dark
            :disabled="availableGroupIds.length === 0"
            :ripple="false"
            @change="selectAll">
          </v-checkbox>
        </template>
        <template #[`group.header`]="{ group, items, headers }">
        <td :colspan="headers.length">
          <v-row align="center">
            <v-col cols="auto">
              <v-icon v-if="groupIds.some(id => id === group)" color="success">
                mdi-check
              </v-icon>
              <template v-else>
                <v-checkbox
                  v-model="selected"
                  color="primary"
                  class="my-auto mx-0"
                  hide-details
                  :value="group"
                  :ripple="false">
                </v-checkbox>
              </template>
            </v-col>
            <v-col class="py-4">
              <div>
                <b>{{ items[0].item_group_name }}</b>
              </div>
              <span class="caption">
                {{ items[0].promo_category_name }}
              </span>
              <span class="caption">
                |
              </span>
              <span class="caption">
                {{ items[0].promo_name }}
              </span>
              <span class="caption">
                |
              </span>
              <span class="caption">
                {{ items[0].start_dt }} - {{ items[0].end_dt }}
              </span>
            </v-col>
          </v-row>
        </td>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { utils } from '@/mixins/utils'
import { format } from '@/mixins/format'
export default {
  name: 'RelatedPromos',
  data () {
    return {
      allSelected: false,
      selected: [],
      nonTableHeight: 300,
      tableSize: '',
      relatedPromos: [],
      headerProps: { sortable: false, class: 'accent white--text' },
      headers: [
        { value: 'select-all' },
        { text: 'Item Id', value: 'item_id',  },
        { text: 'Description', value: 'description' },
        { text: 'UPC', value: 'upc' },
        { text: 'Category', value: 'category' },
        { text: 'Subcategory', value: 'subcategory' },
        { text: 'AMAP', value: 'amap_allowance' },
        { text: 'EBA', value: 'early_buy_allowance' },
        { text: 'Ad BB', value: 'ad_billback' },
        { text: 'EDLC BB', value: 'edlc_billback' },
        { text: 'TPR BB', value: 'tpr_billback' },
        { text: 'Ad Scan', value: 'ad_scan' },
        { text: 'EDLC Scan', value: 'edlc_scan' },
        { text: 'TPR Scan', value: 'tpr_scan' },
        { text: 'Retail Price', value: 'sale_price' },
        { text: 'Case Cost', value: 'cost' },
        { text: 'Pack Size', value: 'pack_size' }
      ],
      formatFields: [
        'ad_scan', 'edlc_scan', 'tpr_scan',
        'billback_allowance', 'amap_allowance', 'early_buy_allowance', 'ad_billback', 'edlc_billback', 'tpr_billback', 'sale_price', 'cost'
      ]
    }
  },
  mixins: [utils, format],
  props: {
    value: Boolean,
    promo: Object,
    related_promos: Array,
    groupIds: Array,
  },
  created() {
    this.tableSize = window.innerHeight - this.nonTableHeight
    this.relatedPromos = this.related_promos.map(promo => this.formatItem(promo))
  },
  computed: {
    tableHeaders() {
      return this.headers.map(header => ({ ...header, ...this.headerProps }))
    },
    relatedGroupIds() {
      return Array.from(new Set([...this.relatedPromos.map(promo => promo.promo_item_group_id)]))
    },
    availableGroupIds() {
      return this.relatedGroupIds.filter(id => this.groupIds.includes(id) === false)
    }
  },
  methods: {
    customSort (items) {
      if (this.$auth.tenant === 'pricechopper') {
        items.sort((a, b) => this.pceComparePromoType(a.promo_type, b.promo_type) || String(a.promo_number).localeCompare(b.promo_number) || String(a.item_id).localeCompare(b.item_id))
      } else {
        items.sort((a, b) => String(a.promo_number).localeCompare(b.promo_number) || String(a.item_id).localeCompare(b.item_id))
      }
      return items
    },
    pceComparePromoType (typeA, typeB) {
      if (typeA === 'AD') {
        return typeB === 'AD' ? 0 : 1
      } else if (typeB === 'AD') {
        return typeA === 'AD' ? 0 : -1
      } else {
        return String(typeA).localeCompare(typeB)
      }
    },
    selectAll (value) {
      this.selected = (value === true)
        ? [...this.availableGroupIds]
        : []
    },
    addGroups () {
      this.$emit('addPromoItemGroups', this.selected)
    },
    formatItem (item) {
      for (const field of this.formatFields) {
        const value = item[field]
        item[field] = (value > 0) ? `$${this.formatCurrency(value)}` : '-'
      }
      return {
        ...item,
        start_dt: this.$dateConfig.formatDate(item.start_dt),
        end_dt: this.$dateConfig.formatDate(item.end_dt)
      }
    },
    formatCurrency (value) {
      if (!value && value !== 0) return null 
      return ((value * 100) / 100).toFixed(2) 
    }
  }
}
</script>